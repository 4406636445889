.pdf-button {
  background-color: var(--buttons);
  height: 40px;
  width: 100px;
  border:none;
  margin-left: auto;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
  margin: 0;
  padding: 10px 16px;
  text-decoration: none;
  white-space: nowrap;
}