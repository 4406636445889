.nav-button select, .nav-button button {
  color:white;
  background: var(--buttons);
  border: none;
  height: 40px;
  width: 100px;
  text-align: center;
  cursor: pointer;
  font-size: 13px;
  margin:2px;
}

.nav-button  {
  height: 40px;
}
.menu-options {
  display: flex;
  background-size: cover;
  justify-content: space-between;
  flex-direction: column;;
  height: 200px;
}

.theme-select{
  font-size: 14px;
}
.language-select > select {
  width: auto;
  padding-left: 32px;
}
.diet-select.nav-button select{
  width: fit-content;
}
.language-select svg {
  height: 30px;
  margin: 2px;
  position: relative;
  right:-37px;
  top:5px;
  --fa-primary-opacity: 1;
  --fa-secondary-opacity: 1;
  --fa-primary-color: rgb(114, 114, 114);
  --fa-secondary-color:rgb(153, 151, 151);
}

.language-select {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.menu-options h2 {
  color:var(--buttons);
  height: fit-content;
  width: fit-content;
  margin-left: auto;
}
.menu-options > div {
  display: flex;
  width: fit-content;
  margin-left: auto;
}
@media screen and (max-width: 768px) {
  .language-select svg {
    margin: 2px;

    --fa-primary-opacity: 1;
    --fa-secondary-opacity: 1;
  }
  .menu-options h2 {
    background-color: rgba(255, 255, 255,1);
    padding: 5px;
    font-size: 23px;
    width: 100%;
  }
}
  
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .nav-button select, .nav-button button {
    padding-left: 8px;
    font-size: smaller;
  }
  .language-select > select {
    width: auto;
    padding-left: 32px;
  }
}}

[data-campus  = "mz"] .diet-select {
  display: none;
}