.menu-header {
  display: flex;
  justify-content: space-between;
  height: 150px;
  overflow: hidden;
  border-top:3px solid var(--secondary);
  margin-bottom: 30px;
}
.menu-header-text{
  font-size: 1.3rem;
  text-align: center;
  width: 50%;
  padding: 10px;
  font-weight: 400;
  color: var(--text);
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.menu-header-text h3,h4 {
  font-family: 'Pluto', sans-serif;
}
.menu-header-text h3 {
  font-size:28px;
}
.menu-header-image{
  width: 50%;
}
.menu-header-image img{
  min-width: 100%;
  min-height: 100%;
}


@media screen and (max-width: 768px) {
.menu-header{
  height: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  }
  .menu-header-text{
    width: 100%;
    text-align: center;
    padding: 0;
    height: 50%;
    font-size: 12px;
  }
  .menu-header-text h3 {
    font-size: 24px;
  }
  .menu-header-image{
    width: 100%;
    height: 50%;
  }
  .menu-items{
    justify-content: space-between;
  }
}



@media print {
  .menu-header {
    margin-bottom: 0!important;
    width: 100%;
  }
  .menu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .menu-header-text{
    box-sizing: border-box;
    color: #727272;
    width: 100%;
    margin: 0;
    text-align: center;
  }
  .menu-header-image{
    display: none;
  }
}

