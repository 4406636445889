.section-container {
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section-container__title {
  background-color: var(--secondary);
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  width: 100%;
  min-height: 40px;
  display: flex;
  align-items: center;}

  .section-container h3 {
    padding-left: 20px;
  }

.section-children{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}


@media screen and (max-width: 768px) {
 .section-children{
   justify-content: space-between;
 }
  
}

@media print {
  .section-container__title {
    color: white!important;
    background-color: #EEB111!important;
    -webkit-print-color-adjust: exact; 
  }
  .section-container {
    padding: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
}