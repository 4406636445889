.menu-nav{
  display: flex;
  flex-direction: co;
  width: 100%;
  height: 200px;
  justify-content: space-between;
  padding: 20px 0;
}
.menu-nav-header h3 {
  cursor: pointer;
}

.menu-nav-items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  font-size: 12px;
  width: 100%;
  /* flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  text-transform: capitalize; */
}
.menu-nav-title{
  color:var(--main);
  text-transform: capitalize;
  padding: 0 10px;
  width: 15%;
}
.menu-nav-subtitle{
  font-size:10px;
}
.menu-nav-item {
  display: inline-block;
  cursor: pointer;
  text-transform: uppercase;
  font-size: small;
  font-weight: bold;
  color: var(--text);
}
.menu-nav-item.active-menu{
  color: var(--main);
}
.menu-nav-select {
  display: none;
}

.menu-nav-header {
  width: 100%;
  background: var(--main);
  height: 50px;
  color: white;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
.menu-nav-header h3 {
  background-color: var(--buttons);
  color: white;
  padding: 5px;
  font-size: 14px;
  width: 200px;
  justify-content: center;
  border-width:0 1px 1px 0;
  border-color: var(--text);
  border-style: solid;
  height: 100%;
  display: flex;
  align-items: center;
}

#menu-nav-nutritional-info{
  background-color: var(--secondary);
}
@media screen and (max-width: 768px) {
 .menu-nav-select {
   display: block;
   text-align: center;
   color:var(--text);
   height: 60px;
   font-family: 'Pluto';
   text-transform: uppercase;
 }
 select.menu-nav-select {
  font-size: 50px;
}
select.menu-nav-select option {
  font-size: 20px;
}
 .menu-nav-header {
   height: 60px;
   text-align: center;
   width: 300px;
   display: flex;
   justify-content: center;
   align-items: center;
 }
 .menu-nav-header h3 {
  border: none;
}
 
 .menu-nav-items, .menu-nav-title {
   display: none;
 }
 .menu-nav{
   padding: 0;
   width: 60px;
   border: none;
   height: auto;
 }
 .menu-wrapper {
   display: flex;
   justify-content: space-between;
 }
}


[data-language = "Chinese"] .menu-nav-header {
  line-height: 1;
}

[data-campus = "mz"] #menu-nav-nutritional-info {
  display: none;
}