
.footer-content{
  display:none;
  font-size:0.9rem;
  padding:15px;
  color:var(--text);
}
.footer h3{
  cursor: pointer;
  width: 100%;
  background-color: var(--main);
  color: white;
  font-size:0.8rem;
  padding-left: 10px;
  height: 30px;
  margin-bottom: 1px;
  display: flex;
  align-items: center;
}

.footer.open .footer-content {
  display: block;
}