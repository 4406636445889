.menu-item{
  height: 170px;
  width:170px;
  color: var(--text);
  border: 1px solid var(--text);
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 12px;
  align-items: left;
  /* padding: 10px; */
}
.menu-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.menu-item__upper{
  padding-top:5px;
  height: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.menu-item:hover .menu-item__image {
  display: none;
}
.menu-item:hover .menu-item__upper {
  height: 30%;
}
.menu-item__image {
  display: flex;
  justify-content: center;
}
.menu-item__image svg {
  height: 80%;;

}
.menu-item__title {
  padding: 0 5px;
}
.menu-item__number {
  padding: 0 5px;
  font-weight: bold;
}
.menu-item__nutrition{
  display: none;
  padding: 5px 0 5px 5px;
  font-size: 11px;
  color:white;
  height: 70%;
}
.menu-item:hover {
  background-color: var(--main);
  color:white;
}

.menu-item:hover .menu-item__nutrition{
  display: block;
}
.menu-item__nutrition ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.menu-item__image {
  width: 100%;
  height: 50%;
  overflow: hidden;
}
[data-language = "Chinese"] .menu-item__nutrition {
  line-height: 1.4;
}

@media print {
  .menu-item{
    color: #727272;
    border: 1px solid #727272;

    /* padding: 10px; */
  }
}