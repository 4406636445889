.menu-hero-header {
  display: grid;
  grid-template-columns: 250px 40px 1fr;
  grid-template-rows: 1fr 1fr;
  padding-bottom: 10px;
}

header.menu-hero-header .menu-hero-header__text{
  color:var(--text);
  display:flex;;
  flex-direction: column;
  justify-content: center;
  padding:10px;
  font-family: 'Pluto';
  width: 70%;
}
.menu-hero-header h1, .menu-hero-header h2, .menu-hero-header h3{
  font-family: 'Pluto';
  color: var(--text);;
}
.menu-hero-header__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  grid-row:1/4;
}

.menu-hero-header__logo img {
  height: 60%;
}
.menu-hero-header h1 {
  border-bottom: 2px solid var(--buttons);
  margin-top: auto;
  grid-column: 3;
}
#menu-header-undertext {
  grid-column: 3;
  align-self: center;
}
.menu-hero-header__divider{
  height: 80%;
  width: 2px;
  grid-column: 2;
  grid-row: 1/4;
  margin: auto;
  background-color: var(--buttons);
}

@media screen  and (max-width: 768px) {
  .menu-hero-header {
    grid-template-columns: 100px 40px 1fr;
  }
  .menu-hero-header__text h1 {
    font-size: 28px;
    margin-left: auto;
  }
  .menu-hero-header__text h2 {
    font-size: 20px;
  }
  .menu-hero-header__text h3 {
    font-size: 16px;
  }
  header.menu-hero-header .menu-hero-header__text {
    padding-right: 2px;
    padding-left: 2px;
  }
  .menu-hero-header__logo img {
    width: 100%;
    height: auto;
  }
  .menu-hero-header {
    justify-content: space-around;
  }
}
