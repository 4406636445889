
@font-face {
  font-family: 'Pluto';
  src: local('Pluto'),
       url('./fonts/Pluto-Regular.otf') format('otf'),
}
@font-face {
  font-family: 'Helvetica Neue';
  src: local('Helvetica Neue'),
       url('./fonts/Helvetica-Neue-Medium-Extended.ttf') format('ttf'),
}
* {
  --main:#83A645;
  --secondary:#EEB111;
  --buttons:#eb711a;
  --text:#727272;
}

* {
  box-sizing: inherit;
}

html {
  box-sizing: border-box !important;
}

select {
  appearance: none;
  background-color: transparent;
  border: none;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
} 
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue';

}

  .App{
    max-width:1536px;
    margin: 0 auto;
  }
  @media print {
    * {
      line-height: 1em;
    }
  }

  