.close-modal {
  margin-left: auto;
  cursor: pointer;
  height: 50px;
}
.close-modal {
  --fa-primary-color: rgb(206, 0, 0);
  --fa-secondary-color: lightgrey;
  --fa-primary-opacity: 1;
  --fa-secondary-opacity: 1;
  font-size: 30px;
  cursor: pointer;
  height: 50px;
  transition: all 0.1s ease-in-out;
}
.modal-text {
  width: 80%;
  padding: 20px 0;
}
.modal-video {
  width: 80%;
  height: 80%;
}
.close-modal:hover {
  --fa-primary-color: white;
  --fa-secondary-color: rgb(206, 0, 0);
  --fa-primary-opacity: 1;
  --fa-secondary-opacity: 1;
}